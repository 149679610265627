import React from "react";
import { Trans } from "@lingui/macro";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import styled from "@emotion/styled";
import NewLayout from "../../components/NewLayout";
import Hero from "../../components/Hero";
import Container from "../../components/Container";
import backgroundImage from "../../images/headers/distributors-investment.png";
import BoxDetails from "../../components/BoxDetails";
import platformImage from "../../images/distributors/investment/platform.png";
import mancoImage from "../../images/distributors/investment/manco.png";
import InterestedItems from "../../components/InterestedItems";
import { additionalLinks, headers, restHeaders } from "../../utils/navbar";
import ArrowButton from "../../components/ArrowButton";

const content = () => [
  {
    title: <Trans>Manco Services</Trans>,
    description: (
      <StyledStack spacing={1}>
        <Typography>
          <Trans>
            As a Luxembourg management company, Allfunds Investment Solutions
            can support distributors and fund houses in a variety of services,
            reducing administrative load and enabling them to broaden their
            commercial reach.
          </Trans>
        </Typography>
        <Box>
          <Typography>
            <b>
              <Trans>Fund hosting and private labelling</Trans>
            </b>
          </Typography>
          <Typography>
            <Trans>
              Our fund hosting & private labelling offering creates a
              sustainable tie to fund houses and distributors
            </Trans>
          </Typography>
        </Box>
      </StyledStack>
    ),
    image: mancoImage,
  },
  {
    title: <Trans>Sub-Advisory Platform</Trans>,
    description: (
      <StyledStack spacing={1}>
        <Typography>
          <Trans>
            Allfunds provides a B2B solutions platform allowing clients to
            swiftly deploy their own Sub-Advisory proposition based on existing
            mandates as well as dedicated building-blocks.
          </Trans>
        </Typography>
        <Box>
          <Typography>
            <b>
              <Trans>Strategic Partnerships</Trans>
            </b>
          </Typography>
          <Typography>
            <Trans>
              Allfunds accelerates your own Sub-Advisory developments thanks to
              customized services adapted to your needs: tailor-made mandates
              corresponding to your asset class and/or manager preferences,
              dedicated fund solutions.
            </Trans>
          </Typography>
        </Box>
        <Box>
          <Typography>
            <b>
              <Trans>Multi-Boutiques Offering</Trans>
            </b>
          </Typography>
          <Typography>
            <Trans>
              This new B2B offering from Allfunds is not limited to pre-selected
              boutique funds available off-the-shelf but rather to an investment
              solution that allows wealth managers to design and implement a
              differentiating investment offer for their clients.
            </Trans>
          </Typography>
        </Box>
      </StyledStack>
    ),
    image: platformImage,
  },
];

function DistributorsInvestmentPage() {
  const interestedItems = [
    { ..._.get(restHeaders(), "about.elements.overview") },
    { ..._.get(restHeaders(), "distributors") },
    { ..._.get(restHeaders(), "fund_houses") },
    { ..._.get(additionalLinks(), "blockchain") },
    { ..._.get(restHeaders(), "about.elements.about_history") },
    { ..._.get(headers(), "esg.elements.approach_esg") },
    { ..._.get(restHeaders(), "about.elements.about_team") },
    { ..._.get(headers(), "contact") },
  ];

  return (
    <NewLayout>
      <Hero
        kind={<Trans>ManCo & Investment Solutions</Trans>}
        title={<Trans>ManCo & Investment Solutions</Trans>}
        description={
          <Trans>
            Allfunds provides ManCo services and investment solutions for banks,
            wealth managers and institutional investors. From fund structuring
            to investment management delegation, Allfunds’ robust service
            offering, combined its extensive fund distribution network, helps
            partners streamline their operations and maximise their sales
            efforts.
          </Trans>
        }
        backgroundImage={backgroundImage}
        buttonText={<Trans>Get in touch</Trans>}
        buttonPath="/contact"
      />
      <Container py={8}>
        <Typography variant="h3">
          <Trans>
            Amplify your product range and benefit from economies of scale
          </Trans>
        </Typography>
        <Stack mt={3} spacing={2}>
          {content().map(({ title, description, image }) => (
            <BoxDetails title={title} description={description} image={image} />
          ))}
        </Stack>
        <Box mt={3} display="flex" justifyContent="end">
          <ArrowButton
            title={<Trans>Access our ManCo website</Trans>}
            path="https://allfunds-is.com/"
            isDark
          />
        </Box>
        <InterestedItems isSmall items={interestedItems} />
      </Container>
    </NewLayout>
  );
}

const StyledStack = styled(Stack)`
  > p,
  * p,
  * b {
    font-size: 18px;
  }
`;

export default DistributorsInvestmentPage;
